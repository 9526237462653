<template>
  <ribbon class="has-background-white has-border-top" padding="none">
    <div class="has-padding-left-100 has-padding-right-100">
      <horizontal-nav :routes="routes" :is-borderless="true" />
    </div>
  </ribbon>
</template>

<script>
export default {
  name: "SiteSubNavigation",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routes: []
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    siteUserRole() {
      return this.$store.getters["sites/site/userRole"](this.siteId);
    },
    userIsAdmin() {
      return this.$store.getters["user/isAdmin"]();
    },
    userIsAgent() {
      return this.$store.getters["user/isAgent"]();
    }
  },
  mounted() {
    this.routes = [
      {
        label: "Tasks",
        to: { path: `/sites/${this.siteId}/tasks` }
      },
      {
        label: "Site Info",
        to: { path: `/sites/${this.siteId}/info` }
      },
      {
        label: "Backups",
        to: { path: `/sites/${this.siteId}/backups` }
      },
      {
        label: "Changes",
        to: { path: `/sites/${this.siteId}/changes` },
        guard: () => {
          return (
            ["wordpress"].includes(this.site.software) &&
            this.$_.get(this.site, "wordpress.hasPlugin")
          );
        }
      },
      {
        label: "Plugins & Themes",
        to: {
          name: "sitePlugins",
          params: {
            siteId: this.siteId
          }
        },
        guard: () => {
          // hasWordpressPlugin === true only when fixed plugin is installed and had called our api
          return (
            ["wordpress"].includes(this.site.software) &&
            this.$_.get(this.site, "wordpress.hasPlugin")
          );
        }
      },
      {
        label: "Monitoring",
        to: { path: `/sites/${this.siteId}/monitoring` }
      },
      {
        label: "Settings",
        to: { path: `/sites/${this.siteId}/settings` },
        guard: () => {
          return this.userIsAdmin || ["owner"].includes(this.siteUserRole);
        }
      },
      {
        label: "Reports",
        to: { path: `/sites/${this.siteId}/reports` },
        guard: () => {
          return this.site.isSubscribed;
        }
      },
      {
        label: "Logs",
        to: { path: `/sites/${this.siteId}/logs` },
        guard: () => {
          return this.userIsAdmin || ["owner"].includes(this.siteUserRole);
        }
      }
    ];
  }
};
</script>
